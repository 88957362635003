/*id4portal.css*/

* {
  font-family: "Open Sans", sans-serif;
}

p {
  margin: 0;
}

h5 {
  white-space: normal;
}

.pointer {
  cursor: pointer;
}

.cursor-auto {
  cursor: auto !important;
}

.text-right {
  text-align: right !important;
}

.text-right-table {
  text-align: right !important;
  padding-left: 0 !important;
}

.span-padding{
  padding-left: 10px;
}

.tr-align-middle{
  vertical-align: middle !important;
}

.inline-flex {
  display: inline-flex;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.overflow-x-auto {
  overflow-x: auto;
}

.background-white {
  background: #fff;
}

.no-btn {
  background: none;
  border: none;
  padding: 0;
}

.cookiebar {
  background-color: #616161;
}

input.input-validation-error,
textarea.input-validation-error,
select.input-validation-error {
  background: #fef1ec !important;
  border: 1px solid #cd0a0a !important;
}

.index-menu > li > a:hover,
.index-menu > li > a:focus,
.index-menu li > a:hover {
  background-color: #2391ed;
  color: #fff;
}

/* Menu colapsado */

.cl-vnavigation .parent:not(.open) > a {
  background: url("../img/arrow.png") no-repeat scroll right 14px top 18px transparent;
}

/* Menu expandido */

.cl-vnavigation .parent.open > a {
  background: url("../img/arrow_up.png") no-repeat scroll right 14px top 18px transparent;
}

.index-menu > li > a,
.index-menu li > a {
  font-size: 12px;
  padding: 5px 20px;
  clear: both;
  color: #333;
  display: block;
  font-weight: normal;
  line-height: 1.42857;
  white-space: nowrap;
}

.index-menu > li {
  list-style-type: none;
}

.index-menu > li > ul > li:not(.dropdown-header) {
  list-style-type: none;
  padding-left: 15px;
}

.index-menu > li:not(:last-child) ul {
  border-right: 1px solid #e8e8e8;
}

.dropdown-header {
  cursor: default !important;
}

.dropdown-menu > li > span:hover,
.dropdown-menu > li > span:focus,
.dropdown-submenu:hover > span,
.dropdown-menu li > span:hover {
  background-color: #2391ed;
  background-image: none;
  color: #fff;
  cursor: pointer;
}

.dropdown-menu > li > span,
.dropdown-menu li > span {
  font-size: 12px;
  clear: both;
  display: block;
  font-weight: normal;
  line-height: 1.42857;
  white-space: nowrap;
}

.dropdown-menu li > span {
  padding: 5px 12px;
  text-align: left;
}

@media (max-width: 767px) {
  .dropdown-menu.col-menu-2 {
    background-color: white !important;
  }
  .dropdown-menu.col-menu-2 li > a {
    color: #333333 !important;
  }
  .dropdown-menu.col-menu-2 li > a:hover {
    color: white !important;
  }
  .dropdown-header {
    color: #2083d6 !important;
  }
  .spaceinxs {
    margin-bottom: 15px;
  }
}

.dropdown > .dropdown-toggle > i {
  padding-right: 4px;
}

.no-resize {
  resize: none;
}

.nav-tabs > li > a.colored {
  background-color: whitesmoke;
}

.nav-tabs > li.active > a.colored {
  background-color: inherit;
}

.fas,
.far {
  min-width: 14px;
}

.dark {
  color: #333 !important;
}

.crimson {
  color: #dc143c !important;
}

.purple_icon {
  color: #742157 !important;
}

.white {
  color: #fff !important;
}

.red {
  color: #dd5a43 !important;
}

.red2 {
  color: #e08374 !important;
}

.light-red {
  color: #f77 !important;
}

.blue {
  color: #478fca !important;
}

.light-blue {
  color: #93cbf9 !important;
}

.green {
  color: #69aa46 !important;
}

.light-green {
  color: #b0d877 !important;
}

.yellow {
  color: #b2b200 !important;
}

.orange {
  color: #ff892a !important;
}

.orange2 {
  color: #feb902 !important;
}

.light-orange {
  color: #fcac6f !important;
}

.purple {
  color: #a069c3 !important;
}

.pink {
  color: #c6699f !important;
}

.pink2 {
  color: #d6487e !important;
}

.brown {
  color: brown !important;
}

.grey {
  color: #777 !important;
}

.light-grey {
  color: #bbb !important;
}

.backgroundColorError {
  background-color: #efd9d9 !important;
}

.grande-text {
  font-size: 20px;
  padding-bottom: 5px;
}

.padding-vertical-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.padding-horizontal-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.padding-horizontal-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.padding-sides-9 {
  padding: 9px 0 !important;
}

.padding-right {
  padding-right: 10px;
}

.padding-bottom {
  padding-bottom: 10px;
}

.padding-bottom-15 {
  padding-bottom: 15px !important;
}

.padding-right-10 {
  padding-right: 10px;
}

.padding-15 {
  padding: 15px;
}

.padding-50 {
  padding: 50px;
}

.margin-4 {
  margin: 4px;
}

.padding-left-15 {
  padding-left: 15px;
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-right-20 {
  margin-right: 20px !important;
}

.margin-left-5 {
  margin-left: 5px !important;
}

.margin-horizontal-15 {
  margin-right: -15px;
  margin-left: -15px;
}

.padding-vertical-7 {
  padding-top: 7px;
  padding-bottom: 7px;
}

.padding-vertical-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.no-margin-top {
  margin-top: 0px !important;
}

.no-margin-bottom {
  margin-bottom: 0px !important;
}

.no-margin-left {
  margin-left: 0px !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

.no-padding-left {
  padding-left: 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

.no-padding-top {
  padding-top: 0 !important;
}

.no-padding-vertical {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.small-bottom {
  margin-bottom: 0px;
}

.no-padding-top-bottom {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.scroll-panel {
  padding-bottom: 15px;
}

.bordered {
  border-style: solid;
  border-width: thin;
  border-color: gainsboro;
  margin-top: 5px;
}

.icon-view {
  padding: 6px 12px;
  line-height: 1;
  text-align: center;
  background-color: #eee;
  width: 1%;
  vertical-align: middle;
  display: table-cell;
}

.group-view {
  position: relative;
  display: table;
  padding-right: 0px;
}

.middle-view {
  margin-right: 5px;
  width: 48.6%;
}

.label-view {
  min-width: 150px;
  display: inline-block;
}

.label-max-view {
  min-width: 220px;
  display: inline-block;
}

.label-min-view {
  min-width: 90px;
  display: inline-block;
}

.label-extramin-view {
  min-width: 70px;
  display: inline-block;
}

.state-label.sl-success {
  background-color: #edf4eb !important;
  border-color: #a7c9a1;
  color: #6ea465 !important;
}

.state-label.sl-danger {
  background-color: #fbf4f3 !important;
  border-color: #d7a59d;
  color: #b7837a !important;
}

.state-label {
  text-shadow: none !important;
  border-width: 1px;
  padding: 6px 12px;
  border: 1px solid;
}

/*Para asterisco de preenchimento obrigatorio*/

.input-icon > .ace-icon {
  padding: 0 3px;
  z-index: 2;
  position: absolute;
  top: 1px;
  bottom: 1px;
  left: 3px;
  line-height: 30px;
  display: inline-block;
  color: #909090;
  font-size: 16px;
}

.input-icon.input-icon-right > .ace-icon {
  left: auto;
  right: 3px;
}

/*formato de link sem ser uma tag 'a'*/

.link {
  cursor: pointer;
}

@font-face {
  font-family: "custom-font-icons";
  src: url("../fonts/custom-font-icons.eot");
  src: url("../fonts/custom-font-icons.eot?#iefix") format("embedded-opentype"),
    url("../fonts/custom-font-icons.woff") format("woff"), url("../fonts/custom-font-icons.ttf") format("truetype"),
    url("../fonts/custom-font-icons.svg#custom-font-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

.fa-broom:before {
  display: inline-block;
  font-family: "custom-font-icons";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  content: "\f101";
}

/*******SPINNER SEMPRE AO MEIO*******/

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  /*Half of the height of the loading graphic*/
  margin-left: -50px;
  /*Half of the width of the loading graphic*/
}

.centered-notfixed {
  top: 50%;
  left: 50%;
  margin-top: -50px;
  /*Half of the height of the loading graphic*/
  margin-left: -50px;
  /*Half of the width of the loading graphic*/
}

html.wait,
html.wait * {
  cursor: wait !important;
  pointer-events: none;
}

/*Modal*/

.md-modal-nomenu {
  position: fixed;
  top: 15%;
  left: 17.5%;
  width: 65%;
  max-width: 900px;
  min-width: 320px;
  height: auto;
  z-index: 2000;
  padding-right: 0px !important;
}

/*Modal*/

.md-modal-nomenu.modal-small {
  left: 27% !important;
  width: 50% !important;
  max-width: 630px !important;
}

.icon-stack {
  text-shadow: 1px 1px 2px white, 0 0 10px white, 0 0 5px white, -2px -2px 2px white, -2px 2px 2px white;
  bottom: 2px;
  left: 5px;
}

.icon-stack-package {
  bottom: -4px;
  left: 5px;
}

.glyphicon-floppy-disk.icon-stack {
  top: 11px;
  font-size: 11px !important;
}

.btn-disabled {
  color: gainsboro !important;
  border-color: gainsboro !important;
}

/*******AVISOS/NOTIFICACOES*******/

.circleinwhite {
  background-color: white;
  width: 50px;
  height: 50px;
  border: 5px solid #f1f1f1;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  padding-top: 5px;
  margin-bottom: 5px;
}

.circleinwhite.success i {
  color: #60c060;
}

.circleinwhite.primary i {
  color: #4d90fd;
}

.circleinwhite.info i {
  color: #5bc0de;
}

.circleinwhite.warning i {
  color: #fc9700;
}

.circleinwhite.danger i {
  color: #da4932;
}

.circleinwhite i {
  font-size: 30px;
  vertical-align: middle;
  color: #666;
}

.gritter-text {
  padding-top: 10px;
  display: flex;
}

.gritter-date {
  font-size: 9px;
}

.gritter-image {
  margin-right: 15px;
}

.gritter-item-wrapper.danger {
  background: rgb(153, 40, 18);
  background: rgba(153, 40, 18, 0.92);
}

.gritter-item-wrapper.warning {
  background: rgb(190, 112, 31);
  background: rgba(190, 112, 31, 0.92);
}

.gritter-item-wrapper.success {
  background: rgb(94, 163, 112);
  background: rgba(94, 163, 112, 0.92);
}

.gritter-without-image {
  width: 270px !important;
}

.group-buttons {
  margin: 0 10px;
}

.group-buttons > div {
  display: inline;
}

@media (min-width: 768px) and (max-width: 1121px) {
  #pcont {
    max-width: 1px;
  }
}

@media (min-width: 1121px) {
  .corrigirLargura {
    /*float: right;*/
    max-width: 1px;
    /*max-width: 1133px*/
  }
}

.paperclip-straight {
  transform: rotate(135deg) scaleX(-1);
  vertical-align: bottom;
}

.fa-large {
  font-size: 2em !important;
}

@media only screen and (max-width: 40em) /* 640 */ {
  #temporario {
    position: relative;
  }
  /*#temporario > a
        {
        }*/
  #temporario:not(:target) > a:first-of-type,
  #temporario:target > a:last-of-type {
    display: block;
  }
  /* first level */
  #temporario > ul {
    height: auto;
    display: none;
    position: absolute;
    left: 0;
    right: 0;
  }
  #temporario:target > ul {
    display: block;
  }
  #temporario > ul > li {
    width: 100%;
    float: none;
  }
  /* second level */
  #temporario li ul {
    position: static;
  }
}

.icheckbox_square-blue.checkbox {
  width: 23px;
}

.table > thead > tr:nth-child(2) > th {
  vertical-align: middle;
}

.table > tbody > tr > td.verticalMiddle {
  vertical-align: middle;
}

.vertical-middle {
  vertical-align: middle !important;
}

.margin-vertical-center {
  margin-top: auto;
  margin-bottom: auto;
}

table tfoot th {
  padding: 10px 8px 5px;
}

.verticalTop {
  vertical-align: top !important;
}

.borderRequired {
  border-color: #ff892a !important;
}

.opacity-5 {
  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  /* IE 5-7 */
  filter: alpha(opacity=50);
  /* Netscape */
  -moz-opacity: 0.5;
  /* Safari 1.x */
  -khtml-opacity: 0.5;
  /* Good browsers */
  opacity: 0.5;
}

.md-close {
  color: #fff;
}

.md-close:focus,
.md-close:hover {
  color: #fff;
}

hr {
  clear: both;
}

.display-inline-flex {
  display: inline-flex !important;
}

.display-grid {
  display: grid;
}

.navbar-header {
  position: relative;
  z-index: 1;
  padding: 0;
}

.navbar-header-size {
  max-height: 50px;
  overflow: hidden;
}

.height-47 {
  height: 47px;
}

.height-36 {
  height: 36px;
}

.lineHeight-34 {
  line-height: 34px;
}

.height-20 {
  height: 20px;
}

.margin-top-3 {
  margin-top: 3px;
}

.margin-top-5 {
  margin-top: 5px !important;
}

.margin-top-7 {
  margin-top: 7px;
}

.margin-top-9 {
  margin-top: 9px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-top-12 {
  margin-top: 12px !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-left-10 {
  margin-left: 10px;
}

.display-inline {
  display: inline !important;
}

.italic {
  font-style: italic;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
  cursor: pointer;
}

.vertical-sub {
  vertical-align: sub;
}

.my-drop-zone {
  border: dotted 3px lightgray;
}

.nv-file-over {
  border: dotted 3px red;
}

/* Default class applied to drop zones on over */

.another-file-over-class {
  border: dotted 3px green;
}

.gallery-cont-custom .item {
  display: inline-block;
  /*width: 25%;*/
  margin-bottom: 20px;
  padding-right: 10px;
  padding-left: 10px;
}

.gallery-cont-custom .item.w2 {
  width: 50%;
}

.gallery-cont-custom .photo {
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.13);
}

.gallery-cont-custom .img {
  position: relative;
  height: 200px;
}

.gallery-cont-custom .img .over .func {
  margin-top: -80px;
  position: relative;
  top: 50%;
  text-align: center;
  transition: margin-top 200ms ease-in-out;
}

.gallery-cont-custom .img .over .func a {
  display: inline-block;
  height: 50px;
  margin-right: 2px;
  width: 50px;
  margin-right: 10px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
}

.gallery-cont-custom .img .over .func a:hover {
  background: rgba(255, 255, 255, 0.3);
}

.gallery-cont-custom .img .over .func i {
  font-size: 20px;
  color: #fff;
  line-height: 2.4;
}

.gallery-cont-custom .item .img:hover .over {
  opacity: 1;
}

.gallery-cont-custom .item .img:hover .over .func {
  margin-top: -25px;
}

.gallery-cont-custom .item .head {
  padding: 10px 10px;
  background: #fff;
}

.gallery-cont-custom .item .head h4 {
  margin: 0;
  font-size: 17px;
}

.gallery-cont-custom .item .head span {
  color: #bdbdbd;
  font-size: 14px;
}

.gallery-cont-custom .item .head span.active {
  color: #38a8ff;
}

.gallery-cont-custom .item .head .desc {
  color: #999;
  font-size: 12px;
}

.gallery-cont-custom .item img {
  width: 100%;
}

/*Photo Hover*/

.gallery-cont-custom .item .over {
  top: 0;
  opacity: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(36, 148, 242, 0.8);
  -moz-transition: opacity 300ms ease;
  -o-transition: opacity 300ms ease;
  -webkit-transition: opacity 300ms ease;
  transition: opacity 300ms ease;
}

@media (max-width: 767px) {
  .gallery-cont-custom .item {
    width: 50%;
  }
  .gallery-cont-custom .item.w2 {
    width: 100%;
  }
}

.loader-container {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1031;
  display: none;
  top: 0;
  left: 0;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  border-bottom: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  top: 50%;
  left: 50%;
  margin: -60px 0 0 -60px;
  position: fixed;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.flag-icon-suffix {
  height: 12px;
}

.flag-icon {
  padding: 8px;
  height: 36px;
}

.flag-icon-selected {
  background-color: #43a0f2;
}

.input-icon {
  position: relative;
}

.input-top-gap {
  margin-top: 15px;
}

@media (max-width: 767px) {
  .mobile-input-top-gap {
    margin-top: 15px;
  }
}

.datetime.input-group,
.datetime-min.input-group,
.datetime-max.input-group,
.time.input-group {
  margin-bottom: 0 !important;
}

.datetime .input-group-addon,
.datetime-min .input-group-addon,
.datetime-max .input-group-addon,
.time .input-group-addon {
  background-color: #2489c5;
  border-color: #555;
  color: #fff;
}

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover,
.bootstrap-datetimepicker-widget table td span.active {
  background-color: #2489c5;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.form-control {
  padding: 6px 14px;
}

.nav-tabs {
  border-bottom: 1px solid #ececec;
}

.nav-tabs > li > a.colored {
  background-color: whitesmoke;
}

.checkbox-sm {
  padding-top: 4px;
}

.checkbox-sm label {
  margin-bottom: 0;
}

.nav-link {
  background-color: whitesmoke;
  margin-bottom: 1px;
  border-bottom-width: 2px !important;
}

.nav-link.active,
.nav > li > a:focus,
.nav > li > a:hover {
  background: transparent;
}

.no-opacity {
  opacity: 1 !important;
}

.full-width {
  width: 100% !important;
}

.half-width {
  width: 50% !important;
}

.width-25 {
  width: 25px;
}

.width-36 {
  width: 36px;
}

.width-45 {
  width: 45px;
}

.width-50 {
  width: 50px !important;
}

.width-59 {
  width: 59px;
}

.width-64 {
  width: 64px;
}

.width-75 {
  width: 75px;
}

.width-85 {
  width: 85px;
}

.width-90 {
  width: 90px;
}

.width-110 {
  width: 110px;
}

.width-150 {
  width: 150px;
}

.width-210 {
  width: 210px;
}

.width-450 {
  width: 450px;
}

.width-950 {
  width: 950px;
}

.min-width-20 {
  min-width: 20px;
}

.min-width-22 {
  min-width: 22px;
}

.min-width-46 {
  min-width: 46px;
}

.min-width-50 {
  min-width: 50px;
}

.min-width-70 {
  min-width: 70px;
}

.min-width-85 {
  min-width: 85px;
}

.min-width-90 {
  min-width: 90px !important;
}

.min-width-110 {
  min-width: 110px;
}

.min-width-150 {
  min-width: 150px !important;
}

.min-width-300{
  min-width: 300px !important;
}

.dropdownSoloItems {
  position: fixed;
  z-index: 10000;
}

.font75per {
  font-size: 75% !important;
}

.header > h3 {
  margin: 0 0 25px 0;
}

.tab-content {
  background-color: #f6f6f6;
}

.panel {
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.panel > .panel-heading {
  background: transparent;
  border: none;
  padding-bottom: 5px;
}

.panel > .panel-heading > h5 {
  display: inline-block;
  border-bottom: 1px dashed #dadada;
  padding-bottom: 5px;
  width: 100%;
  font-family: Open Sans, sans-serif;
  font-weight: 300;
  font-size: 14px;
}

.panel > .panel-heading > h5 > i:first-child {
  margin-right: 10px;
}

.panel > .panel-heading > h5 > i.collapse-icon {
  float: right;
  color: #555 !important;
  margin-right: 0;
}

.panel > .panel-body {
  margin: 0 15px;
}

.panel-card > .panel-heading > .panel-title {
  border: none;
}

.color-default {
  color: #555555 !important;
}

.color-danger {
  color: #ee5037 !important;
}

.color-notes {
  color: #e3ccb9;
}

.background-color-empty {
  background: #fff;
}

.background-color-success {
  background: #5fbf5f !important;
  color: #fff;
}

.background-color-danger {
  background: #ee5037 !important;
  color: #fff;
}

.background-color-disabled {
  background: #9e9e9e !important;
}

.background-color-success a.background-link,
.background-color-danger a.background-link {
  color: #fff;
}

.background-color-disabled a.background-link {
  color: rgba(0, 0, 0, 0.87);
}

.background-color-success button,
.background-color-danger button {
  color: #555;
}

.message-text {
  font-size: 13px !important;
  line-height: 1.1 !important;
}

.no-border th,
.no-border td {
  border-right: 0 !important;
  border-left: 0 !important;
}

.hr-sections {
  height: 1px;
}

.modal-theme h3 {
  margin: 0 0 20px 0;
}

/* DOCKMODAL */

.dockmodal-header {
  background-color: #26265d;
}

.dockmodal-body {
  padding: 10px 0;
  bottom: 50px;
}

.dockmodal-footer {
  background-color: #ddd;
}

.dockmodal-footer-buttonset .btn,
.dockmodal-footer-buttonset .btn:focus,
.dockmodal-footer-buttonset .btn:hover,
.dockmodal-footer-buttonset .btn:active,
.dockmodal-footer-buttonset .btn.active {
  color: #616161;
}

/* FIM DOCKMODAL */

.center-modal-select {
  margin-left: 5px;
  width: 40%;
}

.options-width {
  width: 12%;
}

.options-width-with-excel {
  width: 15%;
}

.text-align-right {
  text-align: right;
}

.width-40 {
  width: 40%;
}

.small-input {
  width: 15%;
}

.line-with-label {
  float: right;
  width: 75%;
}

.margin-top-center-label {
  margin-top: 1.5%;
}

.btn {
  background-color: #fff;
}

.select-delete {
  width: 57px;
}

/*override datepicker css*/

/*.mydp{
  width: 30% !important;
}*/

.form-group {
  margin-top: 0;
  margin-bottom: 5px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-bottom-6 {
  margin-bottom: 6px;
}

.margin-bottom-15 {
  margin-bottom: 15px !important;
}

.margin-bottom-30 {
  margin-bottom: 30px !important;
}

.image-max-size {
  max-height: 200px;
  max-width: 200px;
}

.image-center {
  margin: auto;
}

.border-bottom-h4 {
  border-bottom: 1px dashed #ca452e;
}

.padding-topBottom-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.iconH4 {
  padding-right: 5px;
  color: #ca452e;
}

.btn.btn-white,
.btn.btn-white.no-hover:active,
.btn.btn-white.no-hover:hover {
  background-color: #fff !important;
}

.btn.btn-white {
  text-shadow: none !important;
}

.iconInfo {
  color: #4d90fd;
}

.iconSearch {
  color: #a465d0 !important;
}

.icon-arrow-right,
.icon-arrow-left {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.icon-arrow-right:before {
  content: "\F105";
}

.icon-arrow-left:before {
  content: "\F104";
}

.table-actions tr th:last-child,
.table-actions tr td:last-child {
  width: 1%;
  white-space: nowrap;
}

.datetimepicker-hours > .table-condensed thead th:nth-child(2),
.datetimepicker-minutes > .table-condensed thead th:nth-child(2) {
  color: transparent;
  pointer-events: none;
  cursor: default;
  display: none;
}

.datetimepicker-hours > .table-condensed thead th:nth-child(1),
.datetimepicker-hours > .table-condensed thead th:nth-child(3) {
  color: transparent;
  pointer-events: none;
  cursor: default;
  display: none;
}

#cl-wrapper {
  table-layout: fixed;
  position: relative;
}

.border-top {
  border-top: 1px solid #e2e2e2;
}

.no-background {
  background: transparent;
}

/*#region CHECKBOX SEM MATERIAL*/

.md-checkbox {
  position: relative;
  height: 20px;
  margin: 16px 0;
  font-family: "Roboto", sans-serif;
}

.md-checkbox label {
  cursor: pointer;
}

.md-checkbox label:before,
.md-checkbox label:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
}

.md-checkbox label:before {
  width: 20px;
  height: 20px;
  background: #fff;
  border: 2px solid rgba(0, 0, 0, 0.54);
  border-radius: 2px;
  cursor: pointer;
  transition: background 0.3s;
}

.md-checkbox input[type="checkbox"] {
  outline: 0;
  margin-right: 10px;
  margin-left: 1px;
}

.md-checkbox input[type="checkbox"]:checked + label:before {
  border: none;
}

.md-checkbox input[type="checkbox"]:checked + label:after {
  transform: rotate(-45deg);
  top: 5px;
  left: 4px;
  width: 12px;
  height: 6px;
  border: 2px solid #fff;
  border-top-style: none;
  border-right-style: none;
}

/*#endregion CHECKBOX SEM MATERIAL*/

/*#region RADIOBOX SEM MATERIAL*/

.md-radio {
  margin: 16px 0;
}

.md-radio.md-radio-inline {
  display: inline-block;
}

.md-radio input[type="radio"] {
  display: none;
}

.md-radio input[type="radio"]:checked + label:before {
  animation: ripple 0.2s linear forwards;
}

.md-radio input[type="radio"]:checked + label:after {
  transform: scale(1);
}

.md-radio label {
  display: inline-block;
  height: 20px;
  position: relative;
  /* padding: 0 30px; */
  padding: 0 15px;
  margin-bottom: 0;
  cursor: pointer;
  vertical-align: bottom;
}

.md-radio label:before,
.md-radio label:after {
  position: absolute;
  content: "";
  border-radius: 50%;
  transition: all 0.3s ease;
  transition-property: transform, border-color;
}

.md-radio label:before {
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid rgba(0, 0, 0, 0.54);
}

.md-radio label:after {
  top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
  transform: scale(0);
}

/*#endregion RADIOBOX SEM MATERIAL*/

/*#region INPUT SEM MATERIAL*/

input.materialStyle,
textarea.materialStyle {
  background: none;
  padding: 3px 10px 0px 5px;
  display: block;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #c6c6c6;
  width: calc(100% - 10px) !important;
  box-shadow: none;
}

select.materialStyle {
  background-color: transparent;
  width: calc(100% - 10px) !important;
  padding: 4px 0 0 0;
  border: none;
  border-bottom: 1px solid #c6c6c6;
  box-shadow: none;
  height: calc(85%);
  position: absolute;
}

select.materialStyleDatatable {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #c6c6c6;
  box-shadow: none !important;
}

select.materialStyleFilterCol {
  background-color: transparent;
  width: calc(100% - 10px) !important;
  border: none;
  border-bottom: 1px solid #c6c6c6;
  box-shadow: none;
}

div.withText > input.materialStyle,
div.withText > textarea.materialStyle {
  height: calc(85%);
}

div:not(.withText) > input.materialStyle,
div:not(.withText) > textarea.materialStyle {
  height: 24px;
}

input.materialStyle:focus,
textarea.materialStyle:focus,
select.materialStyle:focus,
select.materialStyleFilterCol:focus {
  outline: none;
  box-shadow: none;
}

label.materialStyleDiv {
  font-size: 13px;
  position: relative;
  bottom: 7px;
  margin-left: 4px;
  float: left;
  transform: scale(0.75);
  left: -5px;
}

input.materialStyle:focus ~ label,
input.materialStyle ~ label.withText,
textarea.materialStyle ~ label.withText,
textarea.materialStyle:focus ~ label {
  float: left;
  top: -11px;
  left: -5px;
  font-size: 13px;
  transform: scale(0.75);
}

input.materialStyle:focus ~ .barra:before,
textarea.materialStyle:focus ~ .barra:before {
  width: calc(100% - 10px);
}

label.materialStyle {
  color: #c6c6c6;
  font-weight: bold;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 1px;
  -webkit-transition: 300ms ease all;
  transition: 300ms ease all;
}

.barra.materialStyle {
  position: relative;
  display: block;
}

.barra.materialStyle:before {
  content: "";
  height: 2px;
  width: 0;
  bottom: 0px;
  position: absolute;
  -webkit-transition: 300ms ease all;
  transition: 300ms ease all;
  left: 0%;
}

div.materialStyle {
  position: relative;
  float: left;
  width: 50%;
}

div.materialStyle.withText {
  margin-top: 4px;
}

/*#region INPUTS MATERIAL*/

.allwidth {
  width: 100%;
}

div.allwidth {
  max-width: none;
  width: 100%;
}

.field-validation-valid {
  font-size: 12px;
}

.textRightOnly {
  text-align: right;
}

/* Exclude Small devices (tablets, 768px and up) */

@media (min-width: 768px) {
  .spaceToRight {
    padding-right: 15px !important;
    padding-left: 0 !important;
  }
  .onlyMobile {
    display: none;
  }
}

/* Small devices (tablets, 768px and up) */

@media (max-width: 768px) {
  .spaceToRight {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .textRightOnly {
    text-align: initial;
  }
  .excludeMobile {
    display: none;
  }
  .onlyMobile .dropdown-menu {
    min-width: 0;
  }
}

/*#region TOOLTIPS */

[class^="tooltip"] {
  position: relative;
  height: 12px;
}

[class^="tooltip"]:after {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  content: attr(data-tooltip);
  padding: 4px 10px;
  top: 1.em;
  left: 50%;
  transform: translateX(-50%) translateY(-2px);
  -ms-transform: translateX(-50%) translateY(-2px);
  -moz-transform: translateX(-50%) translateY(-2px);
  -webkit-transform: translateX(-50%) translateY(-2px);
  -o-transform: translateX(-50%) translateY(-2px);
  background: grey;
  color: white;
  white-space: nowrap;
  z-index: 2;
  border-radius: 2px;
  transition: opacity 0.2s cubic-bezier(0.64, 0.09, 0.08, 1), transform 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  -moz-transition: opacity 0.2s cubic-bezier(0.64, 0.09, 0.08, 1), transform 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  -webkit-transition: opacity 0.2s cubic-bezier(0.64, 0.09, 0.08, 1), transform 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  -o-transition: opacity 0.2s cubic-bezier(0.64, 0.09, 0.08, 1), transform 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
}

[class^="tooltip--"][data-tooltip]:hover:after,
[class^="tooltip--"][data-tooltip].focus:after {
  display: block;
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%) translateY(0);
  -ms-transform: translateX(-50%) translateY(0);
  -moz-transform: translateX(-50%) translateY(0);
  -webkit-transform: translateX(-50%) translateY(0);
  -o-transform: translateX(-50%) translateY(0);
}

.tooltip--left:after {
  top: -4px;
  left: 0;
  transform: translateX(-112%) translateY(0);
  -ms-transform: translateX(-112%) translateY(0);
  -moz-transform: translateX(-112%) translateY(0);
  -webkit-transform: translateX(-112%) translateY(0);
  -o-transform: translateX(-112%) translateY(0);
}

.tooltip--left:hover:after {
  transform: translateX(-110%) translateY(0);
  -ms-transform: translateX(-110%) translateY(0);
  -moz-transform: translateX(-110%) translateY(0);
  -webkit-transform: translateX(-110%) translateY(0);
  -o-transform: translateX(-110%) translateY(0);
}

.tooltip--right:after {
  top: -4px;
  left: 100%;
  transform: translateX(12%) translateY(0);
  -ms-transform: translateX(12%) translateY(0);
  -moz-transform: translateX(12%) translateY(0);
  -webkit-transform: translateX(12%) translateY(0);
  -o-transform: translateX(12%) translateY(0);
}

.tooltip--right:hover:after {
  transform: translateX(10%) translateY(0);
  -ms-transform: translateX(10%) translateY(0);
  -moz-transform: translateX(10%) translateY(0);
  -webkit-transform: translateX(10%) translateY(0);
  -o-transform: translateX(10%) translateY(0);
}

.tooltip--triangle[data-tooltip]:before {
  content: "";
  width: 0;
  height: 0;
  border-left: solid 5px transparent;
  border-right: solid 5px transparent;
  border-bottom: solid 5px grey;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  transform: translateX(-50%) translateY(-2px);
  -ms-transform: translateX(-50%) translateY(-2px);
  -moz-transform: translateX(-50%) translateY(-2px);
  -webkit-transform: translateX(-50%) translateY(-2px);
  -o-transform: translateX(-50%) translateY(-2px);
  top: 1.1em;
  left: 50%;
  transition: opacity 0.2s cubic-bezier(0.64, 0.09, 0.08, 1), transform 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  -moz-transition: opacity 0.2s cubic-bezier(0.64, 0.09, 0.08, 1), transform 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  -webkit-transition: opacity 0.2s cubic-bezier(0.64, 0.09, 0.08, 1), transform 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  -o-transition: opacity 0.2s cubic-bezier(0.64, 0.09, 0.08, 1), transform 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  z-index: 3;
}

.tooltip--triangle[data-tooltip]:hover:before,
.tooltip--triangle[data-tooltip].focus:before {
  display: block;
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%) translateY(0);
  -ms-transform: translateX(-50%) translateY(0);
  -moz-transform: translateX(-50%) translateY(0);
  -webkit-transform: translateX(-50%) translateY(0);
  -o-transform: translateX(-50%) translateY(0);
}

.bigTooltip:after {
  display: none;
}

/*#endregion TOOLTIPS*/

.no-horizontal-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.horizontal-padding-5 {
  padding-left: 5px;
  padding-right: 5px;
}

.findInput {
  width: 100% !important;
  /* font-size: smaller !important; */
}

.padding-top-5 {
  padding-top: 5px;
}

.padding-top-12 {
  padding-top: 12px;
}

.padding-top-14 {
  padding-top: 14px;
}

/* SELECT2 */

.select2-area {
  position: relative;
  /* margin: 5px 0;
    padding: 20px 5px; */
}

.select2-area select {
  width: 100%;
}

.select2-area > .sel-wrap {
  /* margin: 5px 0; */
  /* left: 25px;
    right: 25px;
    top: 25px; */
  margin-top: 13.8px;
  /* padding-bottom: 2px; */
  height: 22px;
}

.sel-wrap > select2 {
  width: 100%;
}

.select2-area > label {
  position: absolute;
  /* top: 30px; */
  /* left: 20px; */
  z-index: 1;
  transition: 0.2s ease-out;
  pointer-events: none;
  color: #555;
  font-weight: bold;
  /* top: 7px; */
  margin-bottom: 0px;
  /* height: 35.4px; */
  padding-top: 14.8px;
}

.select2-area > label.requiredElement:after {
  content: " *";
  /* font-size:11px; */
  color: #ff892a !important;
}

.select2-area.error > label {
  color: #f44336;
}

.select2-area > label.filled {
  /* left: 5px; */
  /* transform: translateY(-45%); */
  display: inherit;
  /* top: 23px; */
  font-size: 75%;
  padding-top: 0;
}

.select2 .selection .select2-selection {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0;
  height: 20.8px;
}

.select2-container--default.select2-container--disabled .select2-selection {
  background: #fff;
}

.select2-container--default.select2-container--disabled .select2-selection__rendered {
  color: rgba(0, 0, 0, 0.38) !important;
}

.select2-container--default .select2-selection__rendered {
  color: #000 !important;
  line-height: 19px !important;
}

.select2-container--default .select2-selection__arrow {
  height: 14px !important;
}

.select2-container *:focus {
  outline: none;
}

.select2-dropdown--below {
  margin-top: 10px;
}

span.select2-container--open .select2-results__options .select2-results__option {
  padding: 10px 16px 16px 10px;
  color: black;
}

span.select2-container--open .select2-results__options .select2-results__option[aria-selected="true"] {
  background-color: Silver;
}

span.select2-container--open .select2-results__options .select2-results__option--highlighted {
  background-color: Gainsboro;
}

span.select2-container--open .select2-dropdown {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.19), 0 8px 17px rgba(0, 0, 0, 0.2);
  border: 1px solid #eee;
}

span.select2-container--open .select2-dropdown .select2-search--dropdown {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 5px rgba(0, 0, 0, 0.26);
  width: 95%;
  margin: 10px auto;
  border-radius: 1px;
}

span.select2-container--open .select2-dropdown .select2-search--dropdown input {
  border-radius: 1px;
}

/* bottom bar */

.bar {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.12);
  position: relative;
  display: block;
}

.bar:before,
.bar:after {
  content: "";
  width: 0;
  height: 2px;
  bottom: 1px;
  position: absolute;
  background: #5264ae;
  transition: 0.2s ease all;
}

.select2-area.error > .sel-wrap > .bar {
  height: 2px;
  background: #f44336;
}

.bar:before {
  left: 50%;
}

.bar:after {
  right: 50%;
}

/* active state */

.select2-container--focus ~ .bar:before,
.select2-container--focus ~ .bar:after,
.select2-container--open ~ .bar:before,
.select2-container--open ~ .bar:after {
  width: 50%;
}

/* END SELECT2 */

.md-select-search {
  padding: 10px 15px;
  background: #fff;
}

.md-select-search .mat-form-field-label-wrapper label,
.md-select-search .mat-input-suffix,
.md-select-search input {
  color: #555555;
}

.md-select-search .mat-form-field-underline,
.md-select-search .mat-form-field-ripple {
  background: #555555;
}

/* WIZARD */

.block-flat,
.block-wizard {
  border: none;
}

.fuelux .wizard {
  background: #f6f6f6;
}

.block-wizard {
  border-bottom: none;
}

.btn-success {
  background-color: #60c060 !important;
  border-color: #54a754;
  color: #fff !important;
}

.btn-danger {
  background-color: #df4b33 !important;
  border-color: #ca452e;
  color: #fff !important;
}

.btn-warning {
  background-color: #ff9900 !important;
  border-color: #e38800;
  color: #fff !important;
}

/* END WIZARD */

.dropdown-menu.ulLists {
  min-width: 0px !important;
  border-radius: 3px !important;
  left: 85% !important;
}

.dropdown-menu.ulListsDetails {
  min-width: 0px !important;
  border-radius: 3px !important;
}

/* .login-container .block-flat .foot {
  min-height: 54px;
} */

tbody .reducedSize {
  font-size: 12px;
}

.tableSmallHeader {
  font-size: 12px;
}

.textAlignCenter {
  text-align: center;
}

.custom-radio-group {
  display: inline-flex;
  flex-direction: column;
}

.custom-radio-group .custom-radio-button {
  margin: 5px;
}

.border-right-default {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.ulListsSubmenu {
  min-width: 0px !important;
  border-radius: 3px !important;
  left: -72% !important;
  top: 0% !important;
}

.subMenuTable > a:after {
  content: none !important;
}

.select-version {
  display: inline-block;
  margin-left: 10px;
  vertical-align: sub;
  min-width: 60px;
}

.backgroundColorWarning {
  background-color: #fece4d !important;
}

.select-width {
  width: 75px;
}

.font-weight-normal {
  font-weight: normal;
}

.pagination-angular {
  margin: 2px !important;
  white-space: nowrap !important;
}

.margin-one {
  margin: 1px !important;
}

.select-margins {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 5px;
}

/* NOVO DATETIMEPICKER */

.bootstrap-datetimepicker-widget table th {
  border: none;
  font-weight: bold;
}

.bootstrap-datetimepicker-widget .timepicker-picker table td {
  border: none;
  padding: 0;
}

.bootstrap-datetimepicker-widget table td {
  border: none;
  padding: 0;
}

.bootstrap-datetimepicker-widget a[data-action] {
  margin-bottom: 0 !important;
  margin-left: 0;
  box-shadow: none;
  border: none;
}

/* FIM - NOVO DATETIMEPICKER */

/* Inicio tabelas "especiais" das notificações (onde cada linha é na verdade uma tabela)*/

table.tableBackgroundColor:nth-child(1n) {
  background: white !important;
}

table.tableBackgroundColor:nth-child(2n) {
  background: #f8f8f8 !important;
}

table.tableBackgroundColor tr:nth-child(n) {
  background: none;
}

table.tableBackgroundColor tbody > tr:last-child > td {
  border-bottom: 1px solid #dadada;
}

table.tableBackgroundColor td {
  border: none;
}

.borderBottomTables {
  border-bottom: 1px solid #dadada !important;
}

/* Fim tabelas especiais */

/*Início configurações thead das datatables com find do tipo mat-input para ficarem mais justas*/

.shortThead div.mat-form-field-wrapper {
  margin-top: 3px !important;
}

/*.shortThead #searchButton,
#clearButton {*/
.shortThead .searchButton,
.clearButton {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

table.remove-thead > thead {
  display: none;
}

/*Fim configurações thead datatables*/

.margin-bottom-neg-8 {
  margin-bottom: -8px;
}

.table-box {
  margin-bottom: 5px;
  padding-top: 15px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.no-border-right {
  border-right: 0 !important;
}

.no-border-left {
  border-left: 0 !important;
}

.no-border {
  border: none;
}

.fixed {
  position: sticky;
  top: 0;
  z-index: 999;
}

.row-selected {
  background-color: #b7ddf2 !important;
}

.arrow-left {
  /* display: inline-block; */
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-right: 4px dashed;
  float: right;
  margin-top: 5%;
}

.inline-block {
  display: inline-block !important;
}

.fa-stack-small {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.navbar-default .navbar-brand {
  /* padding-top: 1px;
    padding-bottom: 1px; */
  padding: 0;
}

.navbar-default .navbar-brand .app-logo {
  max-height: 50px;
}

.details-area {
  padding: 0 34px;
}

.collapsible {
  cursor: pointer;
}

.collapsed {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.navbar-default .navbar-nav > li.button > a {
  border: none;
  box-shadow: none;
}

.navbar-default .navbar-nav > li.button .dropdown-menu .nano {
  height: auto;
  max-height: 200px;
}

.radio-group-line {
  margin: 0 0 7px;
  /* background-color: #333; */
}

.dashboard-banner {
  height: 100px;
}

/*******Ngx-Carousel*******/

.banner .bannerStyle h1 {
  background-color: #ccc;
  min-height: 300px;
  text-align: center;
  line-height: 300px;
}

.banner .ngxcarouselPointDefault {
  position: relative;
  top: -45px;
}

.carousel-control {
  bottom: 20px;
  width: 50px;
  z-index: 1;
}

.carousel-control.left {
  background: none;
}

.carousel-control.right {
  background: none;
}

/*******Ngx-Carousel FIM*******/

.align-checkbox {
  float: left;
  position: relative;
  left: 35%;
}

/* Início tabela da modal Confirmação de Receções de Encomendas (cada linha é na verdade um tbody) */

.reception-table tbody:nth-child(odd) > tr > td {
  background: white !important;
}

.reception-table tbody:nth-child(even) > tr > td {
  background: #f8f8f8 !important;
}

.reception-table tbody {
  border-top: 1px solid #dadada !important;
}
/* Fim tabela da modal Confirmação de Receções de Encomendas */

/* Popups */
.cdk-overlay-container {
  z-index: 1030 !important;
}
/* Fim - Popups */

.cdk-drag-dragging {
  display: none !important;
}

/* Para modals maiores */

@media (min-width: 768px) {
  .modal-xl {
    width: 100%;
    max-width: 700px;
  }
}

/* Fim - Para modals maiores */

.iconTableTooltip {
  min-height: 26px;
  display: inline-block;
}

.text-center .md-checkbox label:before,
.text-center .md-checkbox label:after {
  left: calc(50% - 11px);
}

.text-center .md-checkbox input[type="checkbox"]:checked + label:after {
  left: calc(50% - 11px + 4px);
}

.no-min-width {
  min-width: auto !important;
}

/* Para o browser preservar as mudanças de linha do texto */

.white-space-preserved {
  white-space: pre-wrap;
}

.white-space-nowrap {
  white-space: nowrap;
}

.size-14 {
  font-size: 14px;
}

.checkbox-area {
  padding-top: 12px;
  min-height: 34px;
}

.uppercase {
  text-transform: uppercase;
}

.margin-vertical-6 {
  margin-top: 6px;
  margin-bottom: 6px;
}

.margin-vertical-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.selector-warning {
  padding: 18px 0 0;
}

.text-center .mat-select-placeholder {
  text-align: center !important;
}

.no-border-panel {
  border: none;
  box-shadow: none;
}

.visibility-hidden {
  visibility: hidden;
}

/* NGU-CAROUSEL */

ngu-tile ::ng-deep .tile {
  box-shadow: none !important;
}

/* FIM NGU-CAROUSEL */

/* MATERIAL */

.clean-border > .mat-form-field-wrapper > .mat-form-field-flex > .mat-form-field-infix {
  border-top: 1px;
  padding: 2px;
}

.mat-form-field-label-wrapper {
  /* padding-top: 1em !important; */
  font-weight: bold;
}

.mat-form-field-label {
  color: #555;
  /* top: 0 !important; */
  /* ainda sรณ testado no input */
  /* padding-top: 1em; */
  /*font-size: 13px !important;*/
}

.mat-form-field-label span {
  font-size: 13px !important;
}

.mat-select-placeholder {
  /* font-size: 13px !important; */
}

.mat-form-field-label-wrapper > label {
  max-width: inherit;
}

.mat-select-placeholder.mat-floating-placeholder {
  top: -12px !important;
}

/* OLD */

.mat-form-field-label.mat-float:not(.mat-empty),
.mat-focused .mat-form-field-label.mat-float {
  /* padding-top: 20px; */
}

/* NEW */

.mat-form-field-should-float .mat-form-field-label {
  /* padding-top: 20px; */
}

.mat-input-element.text-center {
  padding: 0;
}

.mat-input-element.one {
  width: calc(100% - 15px - 4px);
}

.mat-icon.one {
  width: 15px !important;
  height: auto !important;
}

.mat-input-element.two {
  width: calc(100% - 32px - 4px);
}

.mat-icon.two {
  width: 32px !important;
}

.mat-input-element.three {
  width: calc(100% - 45px - 4px);
}

.mat-icon.three {
  width: 45px !important;
}

div.mat-input-element.select2-container {
  width: 1px;
}

.mat-form-field-infix > .mat-icon {
  display: inline-block;
}

.mat-form-field-infix {
  padding: 0 !important;
  border-top: none !important;
  width: 100% !important;
  min-height: 18px;
}

.mat-form-field,
.mat-select,
.mat-option {
  font-family: inherit !important;
}

.mat-input-invalid > .mat-form-field-wrapper {
  margin-bottom: 0;
}

.mat-form-field-appearance-legacy:not([class~="ng-invalid"]) .mat-form-field-label,
.mat-form-field-appearance-legacy:not([class~="ng-touched"]) .mat-form-field-label,
.mat-option:not([class~="mat-selected"]) {
  color: #555;
}

.mat-select-disabled .mat-select-value {
  color: #555 !important;
}

.mat-checkbox-disabled .mat-checkbox-label {
  color: #555 !important;
}

/* as labels dos filtros das datables colocar uma cor mais clarinha*/

.findInputContainer {
  width: 100%;
}

.findInputContainer .mat-select-value span {
  font-size: 13px !important;
}

.findInputContainer:not([class~="mat-focused"]) .mat-form-field-label span {
  color: #999;
}

.findInputContainer:not([class~="mat-focused"]) .mat-form-field-underline {
  background-color: #999;
}

.mat-form-field-underline {
  bottom: 0 !important;
}

.mat-form-field-infix > input,
.mat-form-field-infix > textarea,
.mat-form-field-infix > mat-select {
  padding-left: 10px;
  color: #555;
}

/* Área geral do form element */

.mat-form-field-wrapper {
  margin: 1em 0;
  padding-bottom: 2px !important;
  margin-bottom: 0 !important;
}

.withLabel > .mat-form-field-wrapper {
  margin-top: 13px !important;
}

.mat-select-trigger {
  min-width: auto !important;
}

.mat-dialog-container .mat-select-trigger {
  height: 20.5px !important;
}

.mat-dialog-container-background-red mat-dialog-container {
  background-color: red;
  .message-text{
    color: white !important;
  }
}

.mat-input-element:disabled {
  color: inherit !important;
}

.mat-form-field-label label,
.mat-select-placeholder label,
.mat-option label {
  font-weight: 600 !important;
  margin-bottom: 7px;
}

.mat-form-field-label label,
.mat-select-placeholder label,
.mat-option label {
  font-weight: 600 !important;
  margin-bottom: 7px;
}

.requiredElement .mat-select-placeholder::after,
.requiredElement ~ span .mat-form-field-label::after {
  content: " *";
  font-size: 11px;
  color: #ff892a !important;
}

.tableNameRequired::after{
  content: " *";
  font-size: 11px;
  color: #ff892a !important;
}

/* Fix: md-select affects flexbox https://github.com/angular/material2/issues/4289 */

span.mat-select-placeholder.mat-floating-placeholder {
  width: auto !important;
}

.mat-select-placeholder {
  width: auto !important;
}

/* End Fix */

@media (max-width: 767px) {
  .mat-form-field-label-wrapper,
  .mat-checkbox-label {
    font-size: 13px;
  }
}

/* FIM - MATERIAL */

/* CHECKBOX MATERIAL */

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.38) !important;
}

/* FIM - CHECKBOX MATERIAL */

/*LIST MATERIAL*/

.mat-list .mat-list-item,
.mat-nav-list .mat-list-item {
  font-size: 13px !important;
  color: #555 !important;
}

.mat-list .mat-subheader {
  /* font-size: 18px !important; */
  padding: 10px 16px;
  /* color: #555 !important; */
  height: auto !important;
}

.mat-list .mat-list-item,
.mat-list .mat-list-option,
.mat-nav-list .mat-list-item,
.mat-nav-list .mat-list-option,
.mat-selection-list .mat-list-item,
.mat-selection-list .mat-list-option {
  height: 38px !important;
}

/*END LIST MATERIAL*/

/* TABS MATERIAL */

.mat-ripple-element {
  display: none;
}

md-tab-group {
  font-family: "Open Sans", sans-serif !important;
}

span[_nghost-c0],
div[_nghost-c0] {
  display: flex;
  flex-direction: column;
}

span.mat-tab-group-inverted-header,
div.mat-tab-group-inverted-header {
  flex-direction: column-reverse;
}

div.mat-tab-label {
  line-height: 48px;
  height: 48px;
  padding: 0 12px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  box-sizing: border-box;
  opacity: 0.6;
  text-align: center;
  position: relative;
  /* comentei para testar a remoção do width fixo */
  /* min-width: 160px; */
  min-width: auto !important;
  width: auto !important;
  /* fim - comentei para testar a remoção do width fixo */
  white-space: nowrap;
}

div.mat-tab-label:focus {
  outline: 0;
  opacity: 1;
}

md-tab-header.mat-tab-label,
div.mat-tab-label,
md-tab-body.mat-tab-label {
  font-family: "Open Sans", sans-serif !important;
  height: 35px !important;
  font-weight: 600 !important;
}

.mat-tab-label {
  line-height: 35px !important;
}

.mat-tab-label,
.mat-tab-link {
  color: rgb(0, 0, 0);
  color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header,
.mat-tab-nav-bar {
  border-bottom: 1px solid rgb(0, 0, 0);
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.mat-tab-label-active {
  background-color: white;
}

.mat-tab-label.mat-ripple:not(.mat-tab-label-active) {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-tab-label.mat-ripple:not(:last-child) {
  overflow: hidden;
  border-right: 1px solid #b7b7b7;
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: rgb(183, 183, 183);
}

[mat-stretch-tabs] div.mat-tab-label,
[md-stretch-tabs] div.mat-tab-label {
  flex-basis: 0;
  flex-grow: 1;
}

div.mat-tab-body-wrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  transition: height 0.5s cubic-bezier(0.35, 0, 0.25, 1);
}

div.mat-tab-body {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  overflow: hidden;
}

div.mat-tab-body.mat-tab-body-active {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1;
  flex-grow: 1;
}

div.mat-tab-body-content {
  overflow: hidden;
}

.mat-tab-group-dynamic-height div.mat-tab-body.mat-tab-body-active {
  overflow-y: hidden;
}

div.mat-tab-disabled {
  cursor: default;
  pointer-events: none;
}

/* FIM - TABS MATERIAL */

/* MODAL MATERIAL */

.form-modal .mat-dialog-title {
  margin: -24px -24px 0 -24px !important;
  padding: 10px 24px 10px 24px !important;
  color: #fff;
}

.form-modal .mat-dialog-content {
  padding-top: 10px;
  padding-bottom: 10px;
}

.form-modal .mat-dialog-actions {
  margin: 0 -24px -24px -24px !important;
  padding: 10px 24px 10px 24px !important;
  background-color: #ddd;
  color: #fff;
}

/* FIM - MODAL MATERIAL */

/* OTHER MATERIAL */

.mat-button.btn-danger {
  color: #fff;
  background-color: #df4b33;
  border-color: #ca452e;
}

.mat-button,
.mat-fab,
.mat-icon-button,
.mat-mini-fab,
.mat-raised-button {
  min-width: 0 !important;
}

.mat-button.md-small {
  padding-right: 0px;
  padding-left: 0px;
}

/* FIM - OTHER MATERIAL */

/* LOGIN MESSAGE */

.login-container .login-message {
  margin: 0 80px;
  padding: 15px;
}

.login-container .login-form {
  width: 430px;
  margin: 50px auto 0;
}

@media (max-width: 767px) {
  .login-container .login-message {
    margin: 0;
  }
  .login-container .login-form {
    width: 100%;
    padding: 0 15px;
  }
}

/* LOGIN MESSAGE */

/* MAT-SELECT READONLY */

.mat-select-readonly.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42) !important;
  background-image: none !important;
}

/*FIM - MAT-SELECT READONLY */

.no-box-shadow {
  box-shadow: none;
}

/* Manter as configurações das tabelas em Mobile */

@media screen and (max-width: 767px) {
  .table-responsive {
    border: none;
  }
  .table-responsive > .table-bordered {
    border: 1px solid #ddd;
  }
}

.tableColumnSeparator {
  /* linha mais grossa à direita de uma coluna da tabela, usado quando existe uniao de colunas*/
  border-right: 2px solid #dadada !important;
}

/* TIMELINE */
.cbp_tmtimeline:before {
  background: #d3d3d3;
}

.cbp_tmtimeline > li .cbp_tmicon {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  background: #808080;
  box-shadow: 0 0 0 8px #d3d3d3;
}

.cbp_tmtimeline > li .cbp_tmlabel h2 {
  font-size: 20px;
}

.cbp_tmtimeline > li .cbp_tmtime span:first-child {
  color: #808080;
}

.cbp_tmtimeline > li .cbp_tmtime span:last-child {
  font-size: 2em;
}

.mat-select-search-code {
  font-family: monospace;
  font-weight: bold;
}

/* mat-expansion-panel */
.mat-accordion .mat-expansion-panel {
  border: 1px solid #ddd;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05) !important;
}


.fc-now-indicator-line
{
  border-top-width: 4px !important;
}

.fc-now-indicator-arrow
{
  left: 55px !important;
  border-width: 6px !important;
  border-radius: 25px !important;
  border-color: red !important;
}

.fc-axis.fc-time.fc-widget-content
{
  display: flex;
}

.fc-unthemed td.fc-today{
  background: #ffffff;
}

.fc-day-header.fc-widget-header
{
  border-color: transparent !important;
  height: 50px;
}
.example-tree-invisible {
  display: none !important;
}


           
/* .table-container {
  width: 100%;

  .mat-table {
    display: block;
    width: 100%;
    overflow-x: auto;
  }

  mat-header-cell{
    justify-content: center;
  }

  
} */

td.mat-cell{
  vertical-align: middle !important;
}

.on-hover-pointer:hover {
  cursor: pointer;
}


/*
 Request Lines Choose Supplier 
*/


.lines-choose-supplier{
  
  .screen-title{
    margin-top: 15px;
  }

  .filters{
    margin-top: 50px;
  }

  .details-row{
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .detail-panel-header{
    height: 20px;
    display: flex;
    justify-content: end;
    

    .detail-panel-header-content{
      border-bottom: var(--color) 2px solid;
      border-left: var(--color) 2px solid;
      border-right: var(--color) 2px solid;
      border-radius: 5px;

      > span{
        padding: 5px;
      }

    }
    
  }

  .detail-panel{
    flex-basis: 376px;
    border: var(--color) 2px solid;
    border-radius: 10px;
    margin-right: 20px;
    margin-bottom: 20px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    height: 470px;

  }

  .detail-inputs-panel{
    display: grid;
    padding-top: 10px;
  }

  .detail-inputs{
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;

  }

  .detail-header{
    display: flex;
    margin-top: 15px;

    .detail-title{
      font-size: 16px;
      color: #000;
      padding: 0;
    }

    .detail-line{
      font-size: 20px;
      color: #A3A3A3;

      > span{
        float: right;
      }

    }
  }

  .detail-fields{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    color: #A3A3A3;

  }

  .detail-input{
    flex-grow: 1;
    flex-basis: 150px;
    padding-right: 5px;
    padding-top: 7px;
  } 

  .detail-input-bigger{
    flex-grow: 2;
    flex-basis: 200px;
    padding-top: 7px;
  }

  .mat-form-field-label {
    color: #A3A3A3;
    padding-top: 0;
    font-size: 13px;
  }


  .detail-supplier{
    padding-top: 5px;
    padding-left: 0px;
    padding-right: 0px;

    .detail-supplier-chosen{
      margin-bottom: 5px;
    }

    .detail-supplier-list{
      overflow-y: scroll;
      padding-left: 0px;
      padding-right: 0px;
      scroll-margin: 10px;
      max-height: 158px;
      display: flex;
      flex-direction: column;
    }

    .detail-supplier-list::-webkit-scrollbar{
      width: 5px;
      height: 8px;
    }

    .detail-supplier-list::-webkit-scrollbar-thumb{
      background: #A3A3A3;
    }


  }

  .detail-supplier-content{
    border: var(--color) 2px solid;
    border-radius: 5px;
    margin-right: 5px;
  }

  .detail-supplier-content, .detail-supplier-content-list{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 74px;

    .check-button{
      height: 70px;
      padding-left: 3px;
      padding-right: 0px;

      > .mat-radio-label{
        height: 70px;

        > .mat-radio-label-content{
          display: grid;
          height: 70px;
          width: -webkit-fill-available;
          padding-top: 5px;
          margin-right: 5px;
          
          grid-template-areas: 
          "supplier  supplier  total"
          "priceSupplier quantityMinSupplier quantityToOrderSupplier"
          "priceBase quantityMinBase quantityToOrderBase";


          row-gap: 2px;
          column-gap: 10px;
          
          grid-template-rows: auto;
          
          .detail-supplier-content-supplier-title{
            grid-area: supplier;
            grid-column: 1 / span 2;
            align-self: center;
            font-size: small;
            color: #A3A3A3;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .detail-supplier-content-supplier-values{
            font-size: 9px;
            color: #A3A3A3;
            > span{
              font-size: 11px;
            }
          }

          .detail-supplier-content-supplier-total{
            grid-area: total;
            align-self: center;
            font-size: smaller;
            color: #A3A3A3;
            > span{
              font-size: 11px;
            }
          }
    
        }
      }

    }

    .check-button-selected{
      height: 70px;
      padding-left: 3px;
      padding-right: 0px;

      > .mat-radio-label{
        height: 70px;

        > .mat-radio-label-content{
          display: grid;
          height: 70px;
          width: -webkit-fill-available;
          padding-top: 5px;
          margin-right: 5px;

          grid-template-areas: 
          "supplier supplier total"
          "priceSupplier quantityMinSupplier quantityToOrderSupplier"
          "priceBase quantityMinBase quantityToOrderBase";


          align-items: start;

          grid-template-rows: auto;
          
          .detail-supplier-content-supplier-title{
            grid-area: supplier;
            align-self: center;
            font-size: medium;
            color: var(--color);
            grid-column: 1 / span 2;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .detail-supplier-content-supplier-values{
            font-size: smaller;
            color: #A3A3A3;

            > span{
              color: #363535;
              font-size: 12px;
            }

          }

          .detail-supplier-content-supplier-total{
            grid-area: total;
            align-self: center;
            grid-row: 1;
            font-size: smaller;
            color: #A3A3A3;

            > span{
              font-size: 14px;
              > span{
                color: #363535
              }
            }
          }
    
        }
      }

    }
  }

  .detail-supplier-content-list{
    border: #A3A3A3 2px solid;
    border-radius: 5px;
    margin-bottom: 5px;
    margin-left: 15px;
    margin-right: 15px;
  
  }
}

.manage-requests-lines-action-bar{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  > button{
    align-self: center;
    height: 30px;
  }
}

/*
 END - Request Lines Choose Supplier 
 */
